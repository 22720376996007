import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import StackTrace from 'stacktrace-js';
import { useLocalStorage } from '../../services/hooks/useLocalStorage';
import { revokeToken, clearMFPUserSettings } from '../../services/api';
import Loading from '../Loading';

const Logout = () => {
    const [_user, setUser] = useLocalStorage('user', '');
    const [tenant, setTenant] = useLocalStorage('tenant', undefined);
    const [_accessToken, setAccessToken] = useLocalStorage('accessToken', undefined);
    const [_idToken, setIdToken] = useLocalStorage('idToken', undefined);
    const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', undefined);
    const [client, setClient] = useLocalStorage('client', undefined);
    const [_, setSqta] = useLocalStorage('dipa-sqta', '');
    const history = useHistory();

    useEffect(() => {
        async function clearData() {
            try {
                await setTenant(undefined);
                await setUser('');
                await setAccessToken(undefined);
                await setIdToken(undefined);
                await setRefreshToken(undefined);
                await setClient(undefined);
                await setSqta('');
                await clearKMCookies();
            } catch (e) {
                console.alert(e);
            }
        }
        async function clearKMCookies() {
            return new Promise((resolve, reject) => {
                if (window.km_cookie) {
                    try {
                        const urls = [
                            'bizhub.singles',
                            '0a0243a9.xyz',
                            'konicaminoltamarketplace.com',
                        ];
                        const regions = [
                            '',
                            'us'
                        ]
                        urls.forEach(url => {
                            regions.forEach(region => {
                                window.km_cookie.DeleteCookie(`${region}.${url}`, null);
                            });
                        });
                        const externalUrls = [
                            '.google.com',
                            '.youtube.com',
                            'accounts.google.com',
                            'accouints.youtube.com'
                        ]
                        externalUrls.forEach(url => {
                            window.km_cookie.DeleteCookie(`${url}`, null);
                        });
                        resolve(true);
                    } catch (e) {
                        console.log('Failed to clear cookies');
                        reject();
                    }
                } else {
                    resolve(true);
                }
            });
        }
        async function logoutUser(tenant, client) {
            try {
                if (!tenant)
                    throw new Error('tenant not defined')
                if (!client)
                    throw new Error('client not defined')

                const clientId = client?.ClientId;
                const redirectUrlBase = `https://${tenant.slug}.auth.${tenant.region}.amazoncognito.com/logout`

                // Revoke accessToken if possible
                if (tenant && refreshToken && client) {
                    await revokeToken(tenant.slug, refreshToken, client.ClientId, client.ClientSecret, tenant.region);
                }
                // Clear user data stored on MFP
                await clearMFPUserSettings();

                if (clientId) {
                    const params = new URLSearchParams({
                        client_id: clientId,
                        logout_uri: `${window.location.protocol}//${window.location.host}/logout`,
                    })
                    const redirectUrl = `${redirectUrlBase}?${params.toString()}`;

                    window.location = redirectUrl
                } else {
                    history.push('/');
                }
            } catch (e) {
                alert(e);
                const callback = (stackframes) => {
                    const stringifiedStack = stackframes.map(sf => {
                        return sf.toString();
                    }).join('\n');
                    alert(stringifiedStack);
                    history.push('/');
                }
                const errback = (_) => {
                    history.push('/');
                }
                StackTrace.fromError(e).then(callback).catch(errback);
            } finally {
                await clearData() // tenant=null, client=null
                redirect();
            }

        }

        function redirect() {
            history.push('/');
        }

        if (tenant && client) {
            logoutUser(tenant, client);
        } else {
            redirect();
        }
    // eslint-disable-next-line
    }, [
        refreshToken,
        setAccessToken,
        setClient,
        setIdToken,
        setRefreshToken,
        setTenant,
        setUser,
        setSqta
    ]);

    return (
        <Loading/>
    )
}

export default Logout